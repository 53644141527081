<template>
	<v-col cols="12" lg="6" md="6">
		<v-card class="grey lighten-4 pl-6" flat>
			<v-col cols="12" class="pt-6">
				<v-card flat class="grey lighten-4">
					<h1 class="text-h5 font-weight-light">{{ $t('qr.title') }}</h1>
					<h2 class="font-weight-light body-1 pt-6">
						{{ $t('qr.subtitle') }}
					</h2>
				</v-card>
			</v-col>
			<v-row class="pl-4">
				<v-col cols="4" class="py-4">
					<v-card>
						<v-img src="../assets/qrcode.png" transition="scale-transition" />
					</v-card>
				</v-col>
				<v-col cols="4" class="pt-0">
					<v-btn
						outlined
						x-large
						download="QR-Kode-Tryggjobb"
						href="https://www.tryggjobb.no/img/qrcode.39d8f638.png"
						class="mt-4 blue--text text--darken-4 font-weight-light rounded-0"
					>
						<v-icon left> mdi-download </v-icon
						>{{ $t('layout.download') }}</v-btn
					>
				</v-col>
			</v-row>
		</v-card>
	</v-col>
</template>

<script>
export default {
	data() {
		return {
			dialog: false,
		};
	},
};
</script>
