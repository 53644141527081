<template>
	<v-main class="grey lighten-4">
		<HeroComponent />
		<v-container>
			<v-row no-gutters> </v-row>
			<v-row no-gutters class="grey lighten-4 pt-4 pb-10">
				<AccordionComponent />
				<QrComponent />
			</v-row>
		</v-container>
		<v-snackbar v-model="submitted" :timeout="4000" color="success" top>{{
			$t('layout.submitted')
		}}</v-snackbar>
	</v-main>
</template>

<script>
import HeroComponent from '@/components/Banner.vue';
import AccordionComponent from '@/components/Accordion.vue';
import QrComponent from '@/components/Qr.vue';

export default {
	data() {
		return {
			submitted: false,
		};
	},
	mounted() {
		if (this.$route.query.success) {
			this.submitted = true;
			this.$router.replace({ query: {} });
		}
	},
	components: {
		HeroComponent,
		AccordionComponent,
		QrComponent,
	},
};
</script>
